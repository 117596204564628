body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Add Font-Family */
@font-face {
  font-family: "RalewayExtraLight";
  src: local("Raleway"),
    url("./App/Assets/Fonts/Raleway-ExtraLight.ttf") format("truetype");
}
@font-face {
  font-family: "RalewayLight";
  src: local("Raleway"),
    url("./App/Assets/Fonts/Raleway-Light.ttf") format("truetype");
}
@font-face {
  font-family: "RalewayRegular";
  src: local("Raleway"),
    url("./App/Assets/Fonts/Raleway-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "RalewayMedium";
  src: local("Raleway"),
    url("./App/Assets/Fonts/Raleway-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "RalewayBlack";
  src: local("Raleway"),
    url("./App/Assets/Fonts/Raleway-Black.ttf") format("truetype");
}
@font-face {
  font-family: "RalewaySemiBold";
  src: local("Raleway"),
    url("./App/Assets/Fonts/Raleway-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "RalewayBold";
  src: local("Raleway"),
    url("./App/Assets/Fonts/Raleway-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "RalewayExtraBold";
  src: local("Raleway"),
    url("./App/Assets/Fonts/Raleway-ExtraBold.ttf") format("truetype");
}
